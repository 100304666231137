import * as _ from "lodash"
import * as React from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import {
    Alert,
    Button,
    Modal,
    Card
} from "../../wrappers"
import { ExportIntegrationType } from "./ExportIntegrations"
import { LivePager } from "../../LivePager"
import { firestore, currentDatabaseRef } from "../../../config/constants"
import { StripedTable } from "../../../components/StripedTable"
import { RoleRouterProps, withRoleRouter } from '../../../routes'
import { Clipboard2Check } from "react-bootstrap-icons"
import { collection, CollectionReference, deleteDoc, doc, Timestamp } from "firebase/firestore"
import { child, DatabaseReference, get, off, onValue, remove, set, update } from "firebase/database"

// The 'queue' type is for serial queues
type QueueType = "success" | "failure" | "queue"
type MetadataKey = "success_count" | "failure_count" | "last_success" | "last_failure"

interface WarningRequest {
    queueType: QueueType
    deleteAll?: boolean
    retryAll?: boolean
    retryKey?: string
    retryElement?: any
    deleteKey?: string
}

interface ExportIntegrationQueueState {
    configuration?: any
    metadata?: any
    errorDescription?: string
    integrationKey: string
    integrationType: ExportIntegrationType
    showElement?: any
    warningRequest?: WarningRequest
    copied?: boolean
    successQueueRef?: CollectionReference
    failureQueueRef?: DatabaseReference
    queueQueueRef?: DatabaseReference
    successElements?: any
    failureElements?: any
    queueElements?: any
    successFirstPage: boolean
    failureFirstPage: boolean
    queueFirstPage: boolean
}

class ExportIntegrationQueue extends React.Component<RoleRouterProps, ExportIntegrationQueueState> {
    queueFetchLimit = 50
    refreshSuccess?: () => Promise<void>
    refreshFailure?: () => Promise<void>

    constructor(props: RoleRouterProps) {
        super(props)
        this.state = {
            integrationKey: props.router.params.integrationKey,
            integrationType: props.router.params.integrationType,
            configuration: undefined,
            errorDescription: undefined,
            successFirstPage: false,
            failureFirstPage: false,
            queueFirstPage: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const integrationConfigurationRef = child(child(child(accountRef, "configuration/export_integrations"), this.state.integrationType), this.state.integrationKey)
        const integrationConfiguration = (await get(integrationConfigurationRef)).val()
        this.setState({ configuration: integrationConfiguration })

        const triggerType = (integrationConfiguration.trigger ?? {}).type
        if (triggerType) {
            const queueRef = child(accountRef, `export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}`)
            const metadataRef = child(queueRef, "metadata")
            const successQueueRef = collection(firestore, `accounts/${account}/export_integrations/${this.state.integrationType}/trigger_type/${triggerType}/integrations/${this.state.integrationKey}/ttl_success`)

            onValue(metadataRef, snap => {
                this.setState({ metadata: snap.val() })
            })
            this.setState({ successQueueRef: successQueueRef, failureQueueRef: child(queueRef, "failure"), queueQueueRef: child(queueRef, "queue") })
        }
    }

    componentWillUnmount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const triggerType = (this.state.configuration.trigger ?? {}).type
        if (triggerType) {
            const queueRef = child(accountRef, `export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}`)
            const metadataRef = child(queueRef, "metadata")
            off(metadataRef)
            off(child(queueRef, "failure"))
            off(child(queueRef, "success"))
            off(child(queueRef, "queue"))
        }
    }

    renderConfiguration() {
        const config = this.state.configuration
        if (_.isNil(config)) { return }
        return (
            <h1>{config.name}</h1>
        )
    }

    nameForMetadataKey(key: MetadataKey): string {
        switch (key) {

            case "success_count":
                return "Successfully exported item count"
            case "failure_count":
                return "Failed export item count"
            case "last_success":
                return "Last successful export"
            case "last_failure":
                return "Last failed export"

            default:
                return "-"
        }
    }

    formattedValueForMetadata(item: string[]): string {
        const key = item[0]
        const data = item[2]
        switch (key) {
            case "success_count":
            case "failure_count":
                return data
            case "last_success":
            case "last_failure": {
                const date = new Date(Number(data))
                return date.toLocaleString()
            }
            default:
                return "-"
        }
    }

    renderQueueMetadata() {
        const metadata = _.cloneDeep(this.state.metadata)
        if (metadata === undefined) { return }
        if (metadata === null) {
            return <h2>No data has yet been exported for this integration.</h2>
        }

        const successBatchCount = Object.keys(this.state.successElements || {}).length
        let successCount = 0
        for (const key of Object.keys(this.state.successElements || {})) {
            const entry = this.state.successElements[key]
            successCount += entry.batch_count || 1
        }
        let successCountString = `${successCount}`
        if (successBatchCount >= this.queueFetchLimit || successCount >= this.queueFetchLimit || !this.state.successFirstPage) {
            successCountString = `${this.queueFetchLimit}+`
        }
        const failureBatchCount = Object.keys(this.state.failureElements || {}).length
        let failureCount = 0
        for (const key of Object.keys(this.state.failureElements || {})) {
            const entry = this.state.failureElements[key]
            failureCount += entry.batch_count || 1
        }

        let failureCountString = `${failureCount}`
        if (failureBatchCount >= this.queueFetchLimit || failureCount >= this.queueFetchLimit || !this.state.failureFirstPage) {
            failureCountString = `${this.queueFetchLimit}+`
        }
        const keys: MetadataKey[] = ["success_count", "failure_count", "last_success", "last_failure"]
        const tableData: string[][] = []
        for (const key of keys) {
            let value = ""
            switch (key) {
                case "success_count": {
                    value = successCountString
                    break
                }
                case "failure_count": {
                    value = failureCountString
                    break
                }
                default: {
                    if (metadata[key] === undefined) {
                        continue
                    }
                    value = `${metadata[key]}`
                }
            }
            tableData.push([key, this.nameForMetadataKey(key), value])
        }
        if (tableData.length === 0) {
            return
        }
        return (
            <Card className="my-4">
                <Card.Header>
                    Queue metadata
                </Card.Header>

                <Card.Body>
                    <StripedTable>
                        <thead>
                            <tr>
                                {tableData.map(array => {
                                    return (
                                        <th key={array[0]}>{array[1]}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {tableData.map(array => {
                                    return (
                                        <td key={array[0]}>{this.formattedValueForMetadata(array)}</td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    </StripedTable>
                </Card.Body>
            </Card>
        )
    }

    renderTitle(queueType: QueueType) {
        let title: string = ""
        const retryRequest: WarningRequest = { queueType: queueType, retryAll: true }
        const deleteRequest: WarningRequest = { queueType: queueType, deleteAll: true }
        const disabled = Object.keys(this.getElements(queueType) || {}).length === 0
        switch (queueType) {
            case "success":
                title = "Successful exports"
                break
            case "failure":
                title = "Failed exports"
                break
            case "queue":
                title = "Currently exporting items"
                break
        }
        return (
            <span>
                {title}
                {queueType !== "queue" && <div style={{ float: "right", marginTop: "-5px" }}>
                    <Button
                        variant="warning"
                        disabled={disabled}
                        onClick={(event) => {
                            event.stopPropagation()
                            this.setState({ warningRequest: retryRequest })
                        }}
                    >
                        Retry all
                    </Button>
                    &nbsp;
                    <Button
                        variant="danger"
                        disabled={disabled}
                        onClick={(event) => {
                            event.stopPropagation()
                            this.setState({ warningRequest: deleteRequest })
                        }}
                    >
                        Delete all
                    </Button>
                </div>}
            </span>
        )
    }

    renderHeader(queueType: QueueType) {
        return (
            <tr>
                {queueType !== "queue" && <th>Date</th>}
                <th>Data</th>
                {queueType === "failure" && <th>Error</th>}
                {queueType === "success" && <th>Transformed</th>}
                {queueType === "success" && <th>Response</th>}
                {queueType !== "queue" && <th>Retry</th>}
                {queueType !== "queue" && <th>Delete</th>}
            </tr>
        )
    }

    getDocumentFromElement(queueType: QueueType, element: any) {
        if (queueType === "success") {
            const object = JSON.parse(element.element ?? element.json)
            if (!_.isNil(object.element)) {
                return object.element
            } else {
                return object
            }
        } else if (queueType === "queue") {
            return element
        } else {
            return element.element
        }
    }

    renderPagerElement(queueType: QueueType, key: string, element: any) {
        const data = this.getDocumentFromElement(queueType, element)
        let timestamp: Date = new Date()
        const snippet: string = JSON.stringify(data, null, 2)?.substr(0, 100)
        if (queueType === "success") {
            const firestoreTimestamp: Timestamp = element.timestamp
            timestamp = firestoreTimestamp.toDate()
        } else {
            timestamp = new Date(element.timestamp)
        }
        return [(
            <tr key={key}>
                {queueType !== "queue" && <td>{timestamp.toLocaleString()}</td>}
                <td
                    style={{ wordBreak: "break-all" }}
                    onClick={() => {
                        this.setState({ showElement: { element: data, timestamp: timestamp, type: "Exported element" } })
                    }}
                >{snippet}...
                </td>
                {queueType === "failure" && <td style={{ wordBreak: "break-all" }}
                    onClick={() => {
                        if (_.isNil(element.error)) { return }
                        this.setState({ showElement: { element: element.error, timestamp: timestamp, type: "Error" } })
                    }

                    }>{element.error?.substr(0, 100)}</td>}
                {queueType === "success" && <td style={{ wordBreak: "break-all" }} onClick={() => {
                    if (_.isNil(element.transformed)) { return }
                    this.setState({ showElement: { element: element.transformed, timestamp: timestamp, type: "Transformed data" } })
                }}
                >{element.transformed?.substr(0, 100)}</td>}

                {queueType === "success" && <td style={{ wordBreak: "break-all" }} onClick={() => {
                    if (_.isNil(element.response)) { return }
                    this.setState({ showElement: { element: element.response, timestamp: timestamp, type: "Server response" } })
                }}
                >{element.response?.substr(0, 100)}</td>}
                {queueType !== "queue" && <td><Button variant="warning" onClick={() => { this.setState({ warningRequest: { queueType: queueType, retryElement: data, retryKey: key } }) }}>Retry</Button></td>}
                {queueType !== "queue" && <td><Button variant="danger" onClick={() => { this.setState({ warningRequest: { queueType: queueType, deleteKey: key } }) }}>Delete</Button></td>}
            </tr >
        )]
    }

    queueRef(queueType: QueueType) {
        switch (queueType) {
            case "success":
                return this.state.successQueueRef
            case "failure":
                return this.state.failureQueueRef
            case "queue":
                return this.state.queueQueueRef
        }
    }

    renderQueue(queueType: QueueType) {
        const queueRef = this.queueRef(queueType)
        if (queueRef === undefined) { return }
        const defaultExpanded = queueType === "failure"
        return (
            <LivePager
                queueFetchLimit={this.queueFetchLimit}
                defaultExpanded={defaultExpanded}
                queueRef={queueRef}
                renderTitle={() => this.renderTitle(queueType)}
                renderHeader={() => this.renderHeader(queueType)}
                renderElement={(key: string, element: any) => this.renderPagerElement(queueType, key, element)}
                didUpdateElements={(elements, firstPage) => {
                    switch (queueType) {
                        case "success":
                            this.setState({ successElements: elements, successFirstPage: firstPage })
                            break
                        case "failure":
                            this.setState({ failureElements: elements, failureFirstPage: firstPage })
                            break
                        case "queue":
                            this.setState({ queueElements: elements, queueFirstPage: firstPage })
                            break
                    }
                }}
                setRefreshFunction={func => {
                    switch (queueType) {
                        case "success":
                            this.refreshSuccess = func
                            break
                        case "failure":
                            this.refreshFailure = func
                            break
                        case "queue":
                            // For now, there's no need for refreshing this queue
                            break
                    }
                }}
            />
        )
    }

    renderFullElement() {
        const element = this.state.showElement
        if (_.isNil(element)) { return }
        let stringElement: string = ""
        if (_.isString(element.element)) {
            // If string contains JSON, then parse in order to format nicely
            try {
                stringElement = JSON.stringify(JSON.parse(element.element), null, 2)
            } catch {
                stringElement = element.element
            }
        } else {
            stringElement = JSON.stringify(element.element, null, 2)
        }
        return (
            <Modal size="xl" show={true} onHide={() => { this.setState({ showElement: undefined }) }} >
                <Modal.Header closeButton={true}>
                    {_.isNil(element.timestamp) ? <Modal.Title>Full item</Modal.Title> :
                        <Modal.Title>{element.type} {new Date(element.timestamp).toLocaleString()}</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    <CopyToClipboard
                        text={stringElement}
                        onCopy={() => { this.setState({ copied: true }); setTimeout(() => { this.setState({ copied: false }) }, 5000) }}
                    >
                        <Button>
                            <Clipboard2Check />
                        </Button>
                    </CopyToClipboard>
                    <br /><br />
                    {this.state.copied ? <Alert variant="success"> JSON copied to clipboard.</Alert> : null}
                    <pre>{stringElement}</pre>
                </Modal.Body>
            </Modal>
        )
    }

    async refresh(queueType: QueueType) {
        switch (queueType) {
            case "success":
                if (this.refreshSuccess) {
                    await this.refreshSuccess()
                }
                break
            case "failure":
                if (this.refreshFailure) {
                    await this.refreshFailure()
                }
                break
            case "queue":
                // For now, we don't need to refresh the queue since there's no retry or delete on these elements
                break
        }
    }

    async retrySingleElement(queueType: QueueType, key: string, element: any) {
        console.log("Retrying element", key, element)
        if (element === undefined) { return }
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const triggerType = (this.state.configuration.trigger || {}).type
        if (triggerType) {
            const queueRef = child(accountRef, `export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}`)
            const updates: any = {}
            updates[`${queueType}/${key}`] = null
            updates[`queue/${key}`] = element

            await update(queueRef, updates)

            if (queueType === "success" || queueType === "failure") {
                const firestoreRef = doc(firestore, `accounts/${account}/export_integrations/${this.state.integrationType}/trigger_type/${triggerType}/integrations/${this.state.integrationKey}/ttl_${queueType}/${key}`)
                await deleteDoc(firestoreRef)
            }
        }
    }

    async retryElement(queueType: QueueType, key: string, element: any) {
        if (element === undefined) { return }
        this.retrySingleElement(queueType, key, element)

        const triggerType = (this.state.configuration.trigger || {}).type
        // Serial queues need to be triggered in order to start
        if (triggerType === "serial") {
            const triggerRef = child(this.accountRef(), `export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}/trigger`)
            await set(triggerRef, true)
        }
        await this.refresh(queueType)
    }

    accountRef() {
        const account = this.props.role.account_id
        return child(currentDatabaseRef(), `v1/accounts/${account}`)
    }

    async deleteSingleElement(queueType: QueueType, key: string) {
        console.log("Deleting element", key)
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const triggerType = (this.state.configuration.trigger || {}).type
        if (triggerType) {
            const firestoreRef = doc(firestore, `accounts/${account}/export_integrations/${this.state.integrationType}/trigger_type/${triggerType}/integrations/${this.state.integrationKey}/ttl_${queueType}/${key}`)
            await deleteDoc(firestoreRef)

            if (queueType === "failure") {
                const queueRef = child(accountRef, `export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}`)
                await remove(child(child(queueRef, queueType), key))
            }
        }
    }

    async deleteElement(queueType: QueueType, key: string) {
        await this.deleteSingleElement(queueType, key)
        await this.refresh(queueType)
    }

    getElements(queueType: QueueType) {
        switch (queueType) {
            case "failure":
                return this.state.failureElements
            case "success":
                return this.state.successElements
        }
    }

    async retryAllElements(queueType: QueueType) {
        const elements = this.getElements(queueType)
        if (elements === undefined) { return }
        for (const key in elements) {
            const element = elements[key]
            const document = this.getDocumentFromElement(queueType, element)
            await this.retrySingleElement(queueType, key, document)
        }

        const triggerType = (this.state.configuration.trigger || {}).type
        // Serial queues need to be triggered in order to start
        if (triggerType === "serial") {
            const triggerRef = child(this.accountRef(), `export_integrations/${this.state.integrationType}/${triggerType}/${this.state.integrationKey}/trigger`)
            await set(triggerRef, true)
        }
        await this.refresh(queueType)
    }

    async deleteAllElements(queueType: QueueType) {
        const elements = this.getElements(queueType)
        if (elements === undefined) { return }
        for (const key in elements) {
            await this.deleteSingleElement(queueType, key)
        }
        await this.refresh(queueType)
    }

    renderWarningElement() {
        const warning = this.state.warningRequest
        if (warning === undefined) { return }

        let action: () => Promise<void>
        let title: string
        let description: string
        let buttonTitle: string
        if (warning.retryKey !== undefined) {
            action = async () => { await this.retryElement(warning.queueType, warning.retryKey!, warning.retryElement) }
            title = "Retry export of element"
            description = "Are you certain that you wish to retry the export?"
            buttonTitle = "Retry export"
        } else if (warning.deleteKey !== undefined) {
            action = async () => { await this.deleteElement(warning.queueType, warning.deleteKey!) }
            title = `Delete element from ${warning.queueType} list`
            description = "Are you certain that you wish to delete the element?"
            buttonTitle = "Delete element"
        } else if (warning.retryAll !== undefined) {
            action = async () => { await this.retryAllElements(warning.queueType) }
            title = "Retry exports"
            description = "Are you certain that you wish to retry the export of all visible elements?"
            buttonTitle = "Retry export"
        } else if (warning.deleteAll !== undefined) {
            action = async () => { await this.deleteAllElements(warning.queueType) }
            title = "Delete failures"
            description = "Are you certain that you wish to delete all visible elements?"
            buttonTitle = "Delete elements"
        } else {
            return
        }

        return (
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>{description}</Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => { this.setState({ warningRequest: undefined }) }}>Cancel</Button>
                    <Button variant="danger" onClick={async () => { this.setState({ warningRequest: undefined }); await action() }}>{buttonTitle}</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (
            <div>
                {this.state.errorDescription ? <Alert variant="warning">{this.state.errorDescription}</Alert> : null}
                {this.renderConfiguration()}
                {this.renderQueueMetadata()}
                {this.renderQueue("success")}
                {this.renderQueue("failure")}
                {this.state.configuration?.trigger?.type === "serial" && this.renderQueue("queue")}
                {this.renderFullElement()}
                {this.renderWarningElement()}
            </div>
        )
    }
}

export default withRoleRouter(ExportIntegrationQueue)
