import * as React from "react"

import { Card, FormControl } from "../../wrappers"
import { currentDatabaseRef } from "../../../config/constants"
import LoadingButton, { PageState } from "../../PageState"
import { RoleRouterProps, withRouter } from "../../../routes"
import { ToggleButton } from "../../../components/ToggleButton"
import { Form } from "react-bootstrap"
import * as _ from "lodash"
import { child, get, set } from "firebase/database"

interface SharedConfigurationEditProps extends RoleRouterProps {
    path: string
    name: string
    defaultValue: any
    description: JSX.Element | string
    typeValue: "number" | "string" | "boolean"
    mode: "account" | "shop"
}

interface SharedConfigurationEditState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
    value?: any
    defaultValue: any
    override: boolean
    accountDefault: any
}

class SharedConfigurationEdit extends React.Component<SharedConfigurationEditProps, SharedConfigurationEditState> {
    constructor(props: SharedConfigurationEditProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false,
            defaultValue: this.props.defaultValue,
            override: false,
            accountDefault: undefined
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        switch (this.props.mode) {
            case "shop": {
                const shop = this.props.router.params.shopKey

                const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/shops/${shop}/configuration/${this.props.path}`)
                const configSnap = await get(configRef)

                const accountConfigRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/${this.props.path}`)
                const accountConfigSnap = await get(accountConfigRef)

                this.setState({
                    loaded: true,
                    value: configSnap.val(),
                    accountDefault: accountConfigSnap.val(),
                    override: configSnap.val() !== undefined
                })
                break
            }
            case "account": {
                const accountConfigRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/${this.props.path}`)
                const accountConfigSnap = await get(accountConfigRef)

                this.setState({
                    loaded: true,
                    value: accountConfigSnap.val(),
                    override: true
                })

                break
            }
        }
    }

    capitalizeFirstLetter(text: string) {
        if (!text) return ""
        return text.charAt(0).toUpperCase() + text.slice(1)
    }

    default() {
        const d = this.state.accountDefault ?? this.state.defaultValue
        if (_.isNil(d)) {
            return undefined
        } else {
            if (d === true) {
                return "Enabled"
            } else if (d === false) {
                return "Disabled"
            }
            return d
        }
    }

    placeholder() {
        if (this.default() === undefined) {
            return `Enter a value to use for this ${this.props.mode}, or leave it empty to disable the behavior.`
        } else {
            return `Enter a value to use for this ${this.props.mode}, or leave it empty to use the default (${this.default()})`
        }
    }

    render() {
        if (this.props.typeValue === "number" || this.props.typeValue === "string") {
            return (
                <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName={this.props.name}>
                    <Card className="my-4" key="upper-panel" border={this.state.showSuccess ? "success" : "default"}>
                        <Card.Header>
                            {this.state.showSuccess ? `${this.capitalizeFirstLetter(this.props.name)} published successfully` : `${this.capitalizeFirstLetter(this.props.name)}`}
                        </Card.Header>
                        <Card.Body>
                            <p>{this.props.description}</p>
                            <FormControl
                                type="text"
                                name="stock"
                                min={undefined}
                                value={this.state.value ?? ""}
                                placeholder={this.placeholder()}
                                onChange={(e: any) => { this.handleChange(e.target.value) }}
                                autoComplete="off"
                            />
                        </Card.Body>
                        <Card.Footer>
                            <LoadingButton onClick={this.publish} disabled={!this.state.dirty}></LoadingButton>
                        </Card.Footer>
                    </Card>
                </PageState>
            )
        } else {

            return (
                <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName={this.props.name}>
                    <Card className="my-4" key="upper-panel" border={this.state.showSuccess ? "success" : "default"}>
                        <Card.Header>
                            {this.state.showSuccess ? `${this.capitalizeFirstLetter(this.props.name)} published successfully` : `${this.capitalizeFirstLetter(this.props.name)}`}
                        </Card.Header>
                        <Card.Body>
                            <p>{this.props.description}</p>
                            {this.props.mode === "shop" &&
                                <>
                                    <Form.Switch
                                        id="custom-switch"
                                        label={`Override default value (${this.default()})`}
                                        checked={this.state.override}
                                        onChange={(e) => this.handleSwitchChange(e.target.checked)}
                                    />
                                    <br />
                                </>
                            }
                            {this.state.override ? <ToggleButton active={this.state.value} performToggle={async () => { this.handleToggleButton() }} /> : <p><i>Default value ({this.default()}) will be used</i></p>}
                        </Card.Body>
                        <Card.Footer>
                            <LoadingButton onClick={this.publish} disabled={!this.state.dirty}></LoadingButton>

                        </Card.Footer>
                    </Card>
                </PageState>
            )
        }

    }

    async handleToggleButton() {
        this.setState({ value: !this.state.value, dirty: true })
    }

    handleSwitchChange = (value: boolean) => {
        if (value == true) {
            this.setState({ override: true })
        } else {
            this.setState({ override: false, value: null, dirty: true })
        }
    }

    handleChange = (value: string) => {
        let typeValue: any
        if (this.props.typeValue === "number") {
            typeValue = value === "" ? null : parseInt(value)
            if (isNaN(typeValue)) {
                typeValue = null
            }
        } else if (this.props.typeValue === "string") {
            typeValue = value === "" ? null : value
        } else if (this.props.typeValue === "boolean") {
            typeValue = value === "true"
        }
        this.setState({ value: typeValue, dirty: true })
    }

    publish = async () => {
        const account = this.props.role.account_id
        switch (this.props.mode) {
            case "account": {
                const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/${this.props.path}`)

                this.setState({ publishing: true })
                await set(configRef, this.state.value)
                this.setState({ showSuccess: true, publishing: false, dirty: false })
                break
            }
            case "shop": {
                const shop = this.props.router.params.shopKey
                const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/shops/${shop}/configuration/${this.props.path}`)

                this.setState({ publishing: true })
                await set(configRef, this.state.value)
                this.setState({ showSuccess: true, publishing: false, dirty: false })
                break
            }
        }
    }
}

export default withRouter(SharedConfigurationEdit)