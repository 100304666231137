import React, { useState } from "react";
import { PDFTemplate } from "../../../../models/pdf_template/pdf_template";
import { Button, FormControl, FormGroup, Row, ToggleButton, ToggleButtonGroup } from "../../../../components/wrappers";
import { GlobalEditType, GlobalModalEdit } from "./GlobalEdit/GlobalModalEdit";
import { FontButton } from "./FontButton";

interface GlobalParametersProps {
    pdfTemplate: PDFTemplate;
    customFonts: string[];
    updateTemplate: (callback: (template: PDFTemplate) => PDFTemplate) => void;
}
export function GlobalParameters(props: GlobalParametersProps) {

    const [isGlobalEditShowing, setIsGlobalEditShowing] = useState<boolean>(false);
    const [globalEditType, setGlobalEditType] = useState<GlobalEditType>(GlobalEditType.background);
    const alignmentValues = ['center', 'justify', 'left', 'right'];

    return (
        <div>
            <FormGroup className="mb-3" as={Row}>
                <div className="globalValueContainer">
                    <div className="globalLabel"> Text color:</div>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="Leading margin"
                        value={props.pdfTemplate.textColor ?? ""}
                        placeholder="Enter text color"
                        onChange={(event) => {
                            const value = event.currentTarget.value as string;
                            props.updateTemplate((template) => {
                                template.textColor = value.length > 0 ? value : undefined;
                                return template;
                            });
                        }} />
                </div>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <div className="globalValueContainer">
                    <div className="globalLabel"> Font:</div>
                    <FontButton mode="pdf" selectedFont={props.pdfTemplate.font} customFonts={props.customFonts} updatedFont={(font) => {
                        props.updateTemplate((template) => {
                            template.font = font;
                            return template;
                        });
                    }}></FontButton>
                </div>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <div className="globalValueContainer">
                    <div className="globalLabel"> Font size:</div>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Font size"
                        min={0}
                        value={props.pdfTemplate.fontSize ?? ""}
                        placeholder="Enter font size"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            props.updateTemplate((template) => {
                                template.fontSize = value != 0 ? value : undefined;
                                return template;
                            });
                        }} />
                </div>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <div className="globalValueContainer">
                    <div className="globalLabel"> Line spacing:</div>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Line spacing"
                        min={0}
                        value={props.pdfTemplate.lineSpacing ?? ""}
                        placeholder="Enter line spacing"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            props.updateTemplate((template) => {
                                template.lineSpacing = value != 0 ? value : undefined;
                                return template;
                            });
                        }} />
                </div>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <div className="globalValueContainer">
                    <div className="globalLabel"> Page size:</div>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Page size"
                        min={0}
                        value={props.pdfTemplate.pageSize ?? ""}
                        placeholder="Enter page size"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            props.updateTemplate((template) => {
                                template.pageSize = value != 0 ? value : undefined;
                                return template;
                            });
                        }} />
                </div>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <div className="globalValueContainer">
                    <div className="globalLabel"> Alignment:</div>
                    <ToggleButtonGroup
                        type="radio"
                        name="pdf_alignment"
                        value={props.pdfTemplate.alignment ?? ""}
                        onChange={(value) => {
                            props.updateTemplate((template) => {
                                template.alignment = value;
                                return template;
                            });
                        }}
                    >
                        {alignmentValues.map((alignment) => {
                            return <ToggleButton id={alignment} key={alignment} value={alignment}>{alignment}</ToggleButton>;
                        })}
                    </ToggleButtonGroup>
                </div>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <div className="globalValueContainer">
                    <div className="globalLabel"> Scale:</div>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Scale"
                        min={0}
                        value={props.pdfTemplate.scale ?? ""}
                        placeholder="Enter scale"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            props.updateTemplate((template) => {
                                template.scale = value != 0 ? value : undefined;
                                return template;
                            });
                        }} />
                </div>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <div className="globalValueContainer">
                    <div className="globalLabel"> Margins:</div>
                    <Button onClick={() => { showGlobalEditModal(GlobalEditType.margins); }}> Edit PDF margins</Button>
                </div>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <div className="globalValueContainer">
                    <div className="globalLabel"> Background:</div>
                    <Button onClick={() => { showGlobalEditModal(GlobalEditType.background); }}> Edit PDF background</Button>
                </div>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <div className="globalValueContainer">
                    <div className="globalLabel"> Metadata:</div>
                    <Button onClick={() => { showGlobalEditModal(GlobalEditType.metadata); }}> Edit metadata</Button>
                </div>
            </FormGroup>
            <GlobalModalEdit show={isGlobalEditShowing} onCancel={() => setIsGlobalEditShowing(false)} onOK={(pdfTemplate) => { props.updateTemplate(() => { return pdfTemplate; }); setIsGlobalEditShowing(false); }} pdfTemplate={props.pdfTemplate} editType={globalEditType}></GlobalModalEdit>
        </div>
    );

    function showGlobalEditModal(type: GlobalEditType) {
        setIsGlobalEditShowing(true);
        setGlobalEditType(type);
    }
}
