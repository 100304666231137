import * as React from "react"

import { Role } from "../../../config/role"
import { Card, Alert, Modal, Button } from "../../wrappers"
import { firestore, currentDatabaseRef } from "../../../config/constants"
import { StripedTable } from "../../../components/StripedTable"
import { ImportIntegrationType } from "./ImportIntegrations"
import CopyToClipboard from "react-copy-to-clipboard"
import * as _ from "lodash"
import { LivePager } from "../../LivePager"
import { RoleRouterProps, withRoleRouter, withRouter } from '../../../routes'
import { Clipboard2Check } from "react-bootstrap-icons"
import { collection, CollectionReference, deleteDoc, doc, Timestamp, updateDoc } from "firebase/firestore"
import { child, DatabaseReference, get, off, onValue, push, update } from "firebase/database"

type QueueType = "success" | "failure"
type MetadataKey = "success_count" | "failure_count" | "last_success" | "last_failure"

interface WarningRequest {
    queueType: QueueType
    deleteAll?: boolean
    retryAll?: boolean
    retryKey?: string
    deleteKey?: string
}

interface ImportIntegrationQueueState {
    configuration?: any
    metadata?: any
    errorDescription?: string
    integrationKey: string
    integrationType: ImportIntegrationType
    showElement?: any
    warningRequest?: WarningRequest
    copied?: boolean
    successQueueRef?: CollectionReference
    failureQueueRef?: DatabaseReference
    successElements?: any
    failureElements?: any
    successFirstPage: boolean
    failureFirstPage: boolean
}

class ImportIntegrationQueue extends React.Component<RoleRouterProps, ImportIntegrationQueueState> {
    queueFetchLimit = 50
    refreshSuccess?: () => Promise<void>
    refreshFailure?: () => Promise<void>

    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            integrationKey: props.router.params.integrationKey,
            integrationType: props.router.params.integrationType,
            configuration: undefined,
            errorDescription: undefined,
            successFirstPage: false,
            failureFirstPage: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const integrationConfigurationRef = child(child(child(accountRef, "configuration/import_integrations"), this.state.integrationType), this.state.integrationKey)
        const integrationConfiguration = (await get(integrationConfigurationRef)).val()
        this.setState({ configuration: integrationConfiguration })

        const queueRef = child(accountRef, `import_integrations/${this.state.integrationType}/${this.state.integrationKey}`)
        const metadataRef = child(queueRef, "metadata")

        onValue(metadataRef, snap => {
            this.setState({ metadata: snap.val() })
        })
        const successQueueRef = collection(firestore, `accounts/${account}/import_integrations/${this.state.integrationType}/integrations/${this.state.integrationKey}/ttl_success`)

        this.setState({ successQueueRef: successQueueRef, failureQueueRef: child(queueRef, "failure") })
    }

    componentWillUnmount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const queueRef = child(accountRef, `import_integrations/${this.state.integrationType}/${this.state.integrationKey}`)
        const metadataRef = child(queueRef, "metadata")
        off(metadataRef)
        off(child(queueRef, "failure"))
        off(child(queueRef, "success"))
    }

    renderConfiguration() {
        const config = this.state.configuration
        if (_.isNil(config)) { return }
        return (
            <h1>{config.name}</h1>
        )
    }

    nameForMetadataKey(key: MetadataKey): string {
        switch (key) {
            case "success_count":
                return "Successfully imported item count"
            case "failure_count":
                return "Failed import item count"
            case "last_success":
                return "Last successful import"
            case "last_failure":
                return "Last failed import"

            default:
                return "-"
        }
    }

    formattedValueForMetadata(item: string[]): string {
        const key = item[0]
        const data = item[2]
        switch (key) {
            case "success_count":
            case "failure_count":
                return data
            case "last_success":
            case "last_failure": {
                const date = new Date(Number(data))
                return date.toLocaleString()
            }
            default:
                return "-"
        }
    }

    renderQueueMetadata() {
        const metadata = _.cloneDeep(this.state.metadata)
        if (metadata === undefined) { return }
        if (metadata === null) {
            return <h2>No data has yet been imported for this integration.</h2>
        }

        const successBatchCount = Object.keys(this.state.successElements || {}).length
        let successCount = 0
        for (const key of Object.keys(this.state.successElements || {})) {
            const entry = this.state.successElements[key]
            successCount += entry.batch_count || 1
        }
        let successCountString = `${successCount}`
        if (successBatchCount >= this.queueFetchLimit || successCount >= this.queueFetchLimit || !this.state.successFirstPage) {
            successCountString = `${this.queueFetchLimit}+`
        }
        const failureBatchCount = Object.keys(this.state.failureElements || {}).length
        let failureCount = 0
        for (const key of Object.keys(this.state.failureElements || {})) {
            const entry = this.state.failureElements[key]
            failureCount += entry.batch_count || 1
        }

        let failureCountString = `${failureCount}`
        if (failureBatchCount >= this.queueFetchLimit || failureCount >= this.queueFetchLimit || !this.state.failureFirstPage) {
            failureCountString = `${this.queueFetchLimit}+`
        }
        const keys: MetadataKey[] = ["success_count", "failure_count", "last_success", "last_failure"]
        const tableData: string[][] = []
        for (const key of keys) {
            let value = ""
            switch (key) {
                case "success_count": {
                    value = successCountString
                    break
                }
                case "failure_count": {
                    value = failureCountString
                    break
                }
                default: {
                    if (metadata[key] === undefined) {
                        continue
                    }
                    value = `${metadata[key]}`
                }
            }
            tableData.push([key, this.nameForMetadataKey(key), value])
        }
        if (tableData.length === 0) {
            return
        }
        return (
            <Card className="my-4">
                <Card.Header>
                    Queue metadata
                </Card.Header>

                <Card.Body>
                    <StripedTable>
                        <thead>
                            <tr>
                                {tableData.map(array => {
                                    return (
                                        <th key={array[0]}>{array[1]}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {tableData.map(array => {
                                    return (
                                        <td key={array[0]}>{this.formattedValueForMetadata(array)}</td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    </StripedTable>
                </Card.Body>
            </Card>
        )
    }

    getElements(queueType: QueueType) {
        switch (queueType) {
            case "failure":
                return this.state.failureElements
            case "success":
                return this.state.successElements
        }
    }

    getDocumentFromElement(queueType: QueueType, element: any) {
        if (queueType === "success") {
            const object = JSON.parse(element.element ?? element.json)
            return object
        } else {
            return element
        }
    }

    renderTitle(queueType: QueueType) {
        let title: string = ""
        const retryRequest: WarningRequest = { queueType: queueType, retryAll: true }
        const deleteRequest: WarningRequest = { queueType: queueType, deleteAll: true }
        const disabled = Object.keys(this.getElements(queueType) || {}).length === 0
        switch (queueType) {
            case "success":
                title = "Successful imports"
                break
            case "failure":
                title = "Failed imports"
                break
        }
        return (
            <span>
                {title}

                {queueType === "failure" &&
                    <div style={{ float: "right", marginTop: "-5px" }}>
                        <Button
                            variant="warning"
                            disabled={disabled}
                            onClick={(event) => {
                                event.stopPropagation()
                                this.setState({ warningRequest: retryRequest })
                            }}
                        >
                            Retry all
                        </Button>
                        &nbsp;
                        <Button
                            variant="danger"
                            disabled={disabled}
                            onClick={(event) => {
                                event.stopPropagation()
                                this.setState({ warningRequest: deleteRequest })
                            }}
                        >
                            Delete all
                        </Button>
                    </div>
                }
            </span>
        )
    }

    renderHeader(queueType: QueueType) {
        return (
            <tr>
                <th>Date</th>
                <th>Contents</th>
                {queueType === "failure" && <>
                    <th>Error</th>
                    <th>Retry</th>
                    <th>Delete</th>
                </>
                }
            </tr>
        )
    }

    renderPagerElement(queueType: QueueType, key: string, batchElement: any) {
        let entries: any[]
        let isBatchElement: boolean
        const document = this.getDocumentFromElement(queueType, batchElement)
        // Backwards compatibility: Previously, the failure queue used 'elements' instead of 'entries'
        if ((document.entries !== undefined || document.elements !== undefined)) {
            entries = document.entries ?? document.elements
            isBatchElement = true
        } else {
            entries = [document]
            isBatchElement = false
        }
        let timestamp: Date = new Date()
        if (queueType === "success") {
            const firestoreTimestamp: Timestamp = batchElement.timestamp
            timestamp = firestoreTimestamp.toDate()
        } else {
            timestamp = new Date(batchElement.timestamp)
        }

        return entries.map((element, index) => {
            let error = element.error
            if (!_.isString(error)) {
                error = `${error}`
            }
            const compositeKey = isBatchElement ? `${key}.${index}` : key
            return (
                <tr key={compositeKey}>
                    <td>{new Date(timestamp).toLocaleString()}</td>
                    <td
                        style={{ wordBreak: "break-all" }}
                        onClick={() => {
                            const showElement = { element: _.cloneDeep(element), timestamp: timestamp }
                            this.setState({ showElement: showElement })
                        }}
                    >
                        {JSON.stringify(element, null, 2).substr(0, 100)}
                    </td>
                    {queueType === "failure" && <>
                        <td style={{ wordBreak: "break-all" }}>{error}</td>
                        <td><Button variant="warning" onClick={() => { this.setState({ warningRequest: { queueType: queueType, retryKey: compositeKey } }) }}>Retry</Button></td>
                        <td><Button variant="danger" onClick={() => { this.setState({ warningRequest: { queueType: queueType, deleteKey: compositeKey } }) }}>Delete</Button></td>
                    </>}
                </tr>
            )
        })
    }

    queueRef(queueType: QueueType) {
        switch (queueType) {
            case "success":
                return this.state.successQueueRef
            case "failure":
                return this.state.failureQueueRef
        }
    }

    renderQueue(queueType: QueueType) {
        const queueRef = this.queueRef(queueType)
        if (queueRef === undefined) { return }
        const defaultExpanded = queueType === "failure"
        return (
            <LivePager
                queueFetchLimit={this.queueFetchLimit}
                defaultExpanded={defaultExpanded}
                queueRef={queueRef}
                renderTitle={() => this.renderTitle(queueType)}
                renderHeader={() => this.renderHeader(queueType)}
                renderElement={(key: string, element: any) => this.renderPagerElement(queueType, key, element)}
                didUpdateElements={(elements, firstPage) => {
                    switch (queueType) {
                        case "success":
                            this.setState({ successElements: elements, successFirstPage: firstPage })
                            break
                        case "failure":
                            this.setState({ failureElements: elements, failureFirstPage: firstPage })
                            break
                    }
                }}
                setRefreshFunction={func => {
                    switch (queueType) {
                        case "success":
                            this.refreshSuccess = func
                            break
                        case "failure":
                            this.refreshFailure = func
                            break
                    }
                }}
            />
        )
    }

    renderFullElement() {
        const element = this.state.showElement
        if (_.isNil(element)) { return }
        return (
            <Modal size="xl" show={true} onHide={() => { this.setState({ showElement: undefined }) }} >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Import from {new Date(element.timestamp).toLocaleString()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CopyToClipboard
                        text={JSON.stringify(element.element, null, 2)}
                        onCopy={() => { this.setState({ copied: true }); setTimeout(() => { this.setState({ copied: false }) }, 5000) }}
                    >
                        <Button>
                            <Clipboard2Check />
                        </Button>
                    </CopyToClipboard>
                    <br /><br />
                    {this.state.copied ? <Alert variant="success"> JSON copied to clipboard.</Alert> : null}
                    <pre>{JSON.stringify(element.element, null, 2)}</pre>
                </Modal.Body>
            </Modal>
        )
    }

    async refresh(queueType: QueueType) {
        switch (queueType) {
            case "success":
                if (this.refreshSuccess) {
                    await this.refreshSuccess()
                }
                break
            case "failure":
                if (this.refreshFailure) {
                    await this.refreshFailure()
                }
                break
        }
    }

    queueRootRef() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        return child(accountRef, `import_integrations/${this.state.integrationType}/${this.state.integrationKey}`)
    }

    async deleteElement(queueType: QueueType, key: string) {
        const updates: any = {}
        this.handleSingleElement(queueType, updates, key, false)

        await update(this.queueRootRef(), updates)
        await this.refresh(queueType)
    }

    async retryElement(queueType: QueueType, key: string) {
        const updates: any = {}
        this.handleSingleElement(queueType, updates, key, true)

        await update(this.queueRootRef(), updates)
        await this.refresh(queueType)
    }

    async handleSingleElement(queueType: QueueType, updates: any, key: string, retry: boolean) {
        const [elementKey, elementIndex] = key.split(".")
        const elements = this.getElements(queueType)
        let element = (elements || {})[elementKey]
        let compositeElement: any = {}
        if (elementIndex !== undefined) {
            compositeElement = element
            // Backwards compatibility: Previously, the failure queue used 'elements' instead of 'entries'
            const entries = compositeElement.entries || compositeElement.elements
            element = entries[elementIndex]
        }
        if (element === undefined) { return }

        // If element is not a batch entry or if only one item is left, delete entire element - otherwise
        // just remove one child element
        if (elementIndex !== undefined && compositeElement.batch_count > 1) {
            // Delete just one sub-element
            compositeElement.batch_count -= 1
            // Backwards compatibility: Previously, the failure queue used 'elements' instead of 'entries'
            if (compositeElement.entries !== undefined) {
                compositeElement.entries.splice(elementIndex, 1)
            } else if (compositeElement.elements !== undefined) {
                compositeElement.elements.splice(elementIndex, 1)
            }
            updates[`${queueType}/${elementKey}`] = compositeElement

            if (queueType === "failure" || queueType === "success") {
                const firestoreRef = doc(firestore, `accounts/${this.props.role.account_id}/import_integrations/${this.state.integrationType}/integrations/${this.state.integrationKey}/ttl_${queueType}/${elementKey}`)
                await updateDoc(firestoreRef, { element: JSON.stringify(compositeElement) })
            }
        } else {
            compositeElement.batch_count -= 1
            updates[`${queueType}/${elementKey}`] = null
            if (queueType === "success" || queueType === "failure") {
                const firestoreRef = doc(firestore, `accounts/${this.props.role.account_id}/import_integrations/${this.state.integrationType}/integrations/${this.state.integrationKey}/ttl_${queueType}/${elementKey}`)
                await deleteDoc(firestoreRef)
            }
        }
        if (retry) {
            const newKey = push(this.queueRootRef()).key
            const elm = queueType === "failure" ? element.element : element
            if (this.state.integrationType === ImportIntegrationType.recommendations) {
                // NOTE: Currently the recommendations import is not batched while all other
                // imports are
                updates[`queue/${newKey}`] = elm
            } else {
                updates[`queue/${newKey}`] = { batch_count: 1, entries: [elm] }
            }
        }
    }

    async retryAllElements(queueType: QueueType) {
        const elements = this.getElements(queueType)
        if (elements === undefined) { return }
        const updates: any = {}
        for (const key in elements) {
            const element = elements[key]
            if (!_.isNil(element.batch_count)) {
                while (element.batch_count > 0) {
                    await this.handleSingleElement(queueType, updates, `${key}.0`, true)
                }
            } else {
                await this.handleSingleElement(queueType, updates, key, true)
            }
        }

        await update(this.queueRootRef(), updates)
        await this.refresh(queueType)
    }

    async deleteAllElements(queueType: QueueType) {
        const elements = this.getElements(queueType)
        if (elements === undefined) { return }
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const queueRef = child(accountRef, `import_integrations/${this.state.integrationType}/${this.state.integrationKey}`)
        const importRef = collection(firestore, `accounts/${this.props.role.account_id}/import_integrations/${this.state.integrationType}/integrations/${this.state.integrationKey}/ttl_${queueType}`)
        const updates: any = {}
        for (const key in elements) {
            updates[`${queueType}/${key}`] = null
            await deleteDoc(doc(importRef, key))
        }
        await update(queueRef, updates)
        await this.refresh(queueType)
    }

    renderWarningElement() {
        const warning = this.state.warningRequest
        if (warning === undefined) { return }

        let action: () => Promise<void>
        let title: string
        let description: string
        let buttonTitle: string
        if (warning.retryKey !== undefined) {
            action = async () => { await this.retryElement(warning.queueType, warning.retryKey!) }
            title = "Retry import of element"
            description = "Are you certain that you wish to retry the import?"
            buttonTitle = "Retry import"
        } else if (warning.deleteKey !== undefined) {
            action = async () => { await this.deleteElement(warning.queueType, warning.deleteKey!) }
            title = `Delete element from ${warning.queueType} list`
            description = "Are you certain that you wish to delete the element?"
            buttonTitle = "Delete element"
        } else if (warning.retryAll !== undefined) {
            action = async () => { await this.retryAllElements(warning.queueType) }
            title = "Retry imports"
            description = "Are you certain that you wish to retry the import of all visible elements?"
            buttonTitle = "Retry import"
        } else if (warning.deleteAll !== undefined) {
            action = async () => { await this.deleteAllElements(warning.queueType) }
            title = "Delete elements"
            description = "Are you certain that you wish to delete all visible elements?"
            buttonTitle = "Delete elements"
        } else {
            return
        }

        return (
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>{description}</Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => { this.setState({ warningRequest: undefined }) }}>Cancel</Button>
                    <Button variant="danger" onClick={async () => { this.setState({ warningRequest: undefined }); await action() }}>{buttonTitle}</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (
            <div>
                {this.state.errorDescription ? <Alert variant="warning">{this.state.errorDescription}</Alert> : null}
                {this.renderConfiguration()}
                {this.renderQueueMetadata()}
                {this.renderQueue("success")}
                {this.renderQueue("failure")}
                {this.renderFullElement()}
                {this.renderWarningElement()}
            </div>
        )
    }
}

export default withRoleRouter(ImportIntegrationQueue)
