//
//  MarketEdit.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 04/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import { Alert, Button, Col, Form, FormControl, FormGroup, Card, ToggleButton, ToggleButtonGroup, DropdownButton, MenuItem, Table } from "../wrappers"
import { currentDatabaseRef } from "../../config/constants"
import { Dictionary, cloneDeep } from "lodash"
import { Tax, Currency, Market } from "../../models/MarketModels"
import * as React from "react"
import LoadingButton, { PageState } from "../PageState"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { publish } from "../../helpers/ModelPublisher"
import { Globals } from "../../helpers/globals"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import { Dropdown, Row } from "react-bootstrap"
import { ProductGroup } from "../../models/Product"
import { StripedTable } from "../StripedTable"
import * as _ from "lodash"
import { DeleteButton } from "../DeleteButton"
import { LabelledControl } from "./ProductEdit"
import { child, get, set } from "firebase/database"

interface MarketEditState {
    market: Market
    new: boolean
    identifier: string
    error: string | null
    taxes: Tax[]
    currencies: Currency[]
    productGroups: ProductGroup[]
    productGroupTaxesMap: Map<string, string[]>,
    dirty: boolean
    publishing: boolean
    loading: boolean
    countries: _.Dictionary<string>
    fiscalCountries: _.Dictionary<string>
    fiscalRule: string 
    fiscalRulesModuleEnabled: boolean
}

class MarketEdit extends React.Component<RoleRouterProps, MarketEditState> {

    // Lifecycle

    constructor(props: RoleRouterProps) {
        super(props)
        this.state = {
            market: { id: "", name: "", taxes: new Set(), currency: "DKK", product_group_taxes: new Map()},
            new: true,
            identifier: "",
            error: null,
            taxes: [],
            currencies: [],
            productGroups: [],
            productGroupTaxesMap: new Map(),
            dirty: false,
            loading: true,
            publishing: false,
            fiscalRule: "none",
            fiscalCountries: {
                se: "Swedish",
                no: "Norwegian",
                dk: "Danish"
            },
            countries: {},
            fiscalRulesModuleEnabled: false
        }
    }

    // Component

    async componentDidMount() {
        await this.load()
    }

    async selectFiscalCountry(countryCode: string, marketId: string) {
        this.setState({fiscalRule: countryCode})
        let countries = _.cloneDeep(this.state.countries)
        let currentFiscalRule = Object.keys(countries).includes(marketId) 
        const account = this.props.role.account_id
        
        if (currentFiscalRule) {
            delete countries[marketId]
        }
        if (countryCode !== "none") {
            countries[marketId] = countryCode
        }
        let fiscalRulesModuleEnabled = Object.keys(countries).length !== 0 ? true : false
        this.setState({countries: countries, fiscalRulesModuleEnabled: fiscalRulesModuleEnabled, dirty: true})
        

    }

    render() {
        return (
            <PageState loading={this.state.loading} typeName="Market" publishing={this.state.publishing} dirty={this.state.dirty}>

                <Card className="my-4">
                    <Card.Header>{this.state.new ? `New market` : `Editing market: ${this.state.market.name}`}</Card.Header>
                    <Card.Body>

                        <Form onSubmit={e => e.preventDefault()}>

                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Name</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={this.state.market.name}
                                        placeholder="Enter name"
                                        onChange={this.handleInputChange}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                            <Col sm={2}>Fiscalization rules</Col>
                                <Col sm={10}>
                                    <>
                                    <DropdownButton
                                    variant="primary"
                                    title={this.state.fiscalCountries[this.state.fiscalRule] ?? "None"}
                                    id="dropdown-country"
                                    onSelect={(selectedFiscalRule: any) => {
                                        this.selectFiscalCountry(selectedFiscalRule, this.state.market.id)
                                    }}
                                    >
                                        <MenuItem key={"none"} eventKey={"none"}>None</MenuItem>
                                        {
                                            Object.keys(this.state.fiscalCountries).sort().map((countryCode, name, countries) => {
                                                const countryName = this.state.fiscalCountries[countryCode]
                                                return <MenuItem key={countryCode} eventKey={countryCode}>{countryName}</MenuItem>
                                            })   
                                        }
                                    </DropdownButton>
                                    </>
                                </Col>
                            </FormGroup>
                            <ValidatingIdEntryControl
                                collectionRef={this.marketsRef()}
                                isNew={this.state.new}
                                typeName="market"
                                identifierSource={this.state.market.name}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                            />
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Currency</Col>
                                <Col sm={10}>
                                    <ToggleButtonGroup
                                        type="radio"
                                        name="currency"
                                        value={this.state.market.currency}
                                        onChange={this.handleCurrencyChange}
                                    >
                                        {
                                            this.state.currencies.map((currency) => {
                                                return <ToggleButton id={currency.currencyCode} key={currency.currencyCode} value={currency.currencyCode}>{currency.currencyCode}</ToggleButton>
                                            })
                                        }
                                    </ToggleButtonGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Default Taxes</Col>
                                <Col sm={10}>
                                    <LabelledControl>
                                    <Dropdown onSelect={tax => {
                                        this.handleTaxesChange(tax)
                                    }}>
                                        <Dropdown.Toggle size="sm" variant="outline-primary">{this.getDescription(Array.from(this.state.market.taxes))}</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {Array.from(this.state.taxes).map((tax) => {
                                                return <Dropdown.Item id={tax.id} active={this.state.market.taxes.has(tax.id)} key={tax.id} eventKey={tax.id}>{tax.name} ({tax.rate * 100}%)</Dropdown.Item>
                                            })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </LabelledControl>
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Product Group Taxes</Col>
                                <Col sm={10}>

                                <DropdownButton
                                    title="Add product group"
                                    id="dropdown-add-attribute"
                                    onSelect={(selectedGroup: any) => {
                                        let currentProductGroupTaxes = this.state.productGroupTaxesMap
                                        const group = this.state.productGroups.find((value) => value.group == selectedGroup )
                                        if (_.isNil(group)) { return }
                                        if (currentProductGroupTaxes.has(group.group)) { return }
                                        this.setState((prevState) => ({ 
                                            productGroupTaxesMap: prevState.productGroupTaxesMap.set(group.group, [])
                                         }))
                                    }}
                                >
                                    {

                                    this.state.productGroups.map((group) => {
                                        return <MenuItem key={group.group} eventKey={group.group}>{this.productGroupName(group.group)}</MenuItem>
                                    })} 
                                    </DropdownButton>
                                </Col>
                            </FormGroup>

                            <FormGroup className="mb-3" as={Row}>
                            <Col sm={6}>
                                {
                                    this.state.productGroupTaxesMap.size > 0 ? (
                                        <Table className="table-borderless" >
                                            <thead>
                                                <tr>
                                                    <th>Product Group</th>
                                                    <th>Taxes</th>
                                                    <th> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { 
                                                Array.from(this.state.productGroupTaxesMap).map(([productGroup, taxes]) => {
                                                    return (
                                                    <tr key={productGroup}>
                                                        <td>{
                                                        this.productGroupName(productGroup)
                                                        }</td>
                                                        <td style={{paddingTop: 35}}>
                                                        <LabelledControl>
                                                            <Dropdown onSelect={tax => {
                                                                this.handleTaxesChangeOnProductGroup(productGroup, tax)
                                                            }}>
                                                                <Dropdown.Toggle size="sm" variant="outline-primary">{this.getDescription(taxes)}</Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {this.state.taxes.map((tax) => {
                                                                        return <Dropdown.Item id={tax.id} active={taxes.includes(tax.id)} key={tax.id} eventKey={tax.id}>{tax.name} ({tax.rate * 100}%)</Dropdown.Item>
                                                                    })
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            </LabelledControl>
                                                        </td>
                                                        <td>
                                                        <DeleteButton onDelete={() => { 
                                                            const productGroupTaxesMap = this.state.productGroupTaxesMap
                                                            productGroupTaxesMap.delete(productGroup)
                                                            this.setState({
                                                                dirty: true,
                                                                productGroupTaxesMap: productGroupTaxesMap
                                                             })
                                                        }} />
                                                        </td>
                                                    </tr>
                                                    )
                                                })                                                
                                                }
                                            </tbody>
                                        </Table>
                                    ) : null
                                }
                                </Col>
                            </FormGroup>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                    <LoadingButton onClick={this.publish} disabled={!this.state.dirty}/>
                    </Card.Footer>
                </Card>
                {this.state.error ? (
                    <Alert variant="danger">
                        <strong>Error publishing market:</strong> {this.state.error}
                    </Alert>
                ) : []}
            </PageState>
        )
    }

    // Methods

    handleCurrencyChange = (data: any) => {
        const market = this.state.market
        market.currency = data
        this.setState({ market: market, dirty: true, error: null })
    }

    handleTaxesChange = (taxKey: string | null) => {
        if (_.isNil(taxKey)) { return }
        const currentTaxes = _.cloneDeep(this.state.market.taxes)
        if (currentTaxes.has(taxKey)) {
            currentTaxes.delete(taxKey)
        } else {
            currentTaxes.add(taxKey)
        }
        const market = this.state.market
        market.taxes = currentTaxes
        this.setState({ market: market, dirty: true, error: null })
    }

    handleTaxesChangeOnProductGroup = (group: string, taxId: string | null) => {
        
        if (_.isNil(taxId)) { return }
        const current = this.state.productGroupTaxesMap.get(group) ?? []
        if (current.includes(taxId)) {
            const index = current.findIndex((tax) => tax == taxId)
            current.splice(index, 1)
        } else {
            current.push(taxId)
        }
        this.setState((prevState) => ({
            dirty: true,
            productGroupTaxesMap: _.cloneDeep(prevState.productGroupTaxesMap).set(group, current)
         }))
    }

    handleInputChange = (event: any) => {
        const target = event.target
        const value = target.value

        const market = cloneDeep(this.state.market)
        market.name = value || ""
        this.setState({ market: market, dirty: true, error: null })
    }

    private getDescription(taxes: string[]): React.ReactNode {
        const selectedTaxes = taxes.map((taxId) => {
            return this.state.taxes.find((tax) => tax.id == taxId)
        }).flatMap((tax) => !_.isNil(tax) ? [tax] : [])

        return selectedTaxes.length == 1 ? selectedTaxes[0].name : taxes.length == 0 ? "Select tax" : `Multiple taxes selected (${selectedTaxes.length})`
    }

    private productGroupName(productGroup: string): React.ReactNode {
        const productGroups = this.state.productGroups.filter((group) => group.group == productGroup)
        if (productGroups.length > 0) {
            return productGroups[0].name.localized(null)
        }
        return productGroup
    }

    handleIdChange(identifier: string) {
        this.setState({ dirty: true, error: null, identifier: identifier })
    }

    marketsRef() {
        return child(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}`), "markets")
    }

    async load() {
        const promises: Promise<any>[] = [
            Globals.shared.getCurrencies(),
            Globals.shared.getTaxes()
        ]
        const marketKey = this.props.router.params.marketKey !== "new" ? this.props.router.params.marketKey : null

        let isNewMarket = true
        let market = this.state.market

        if (marketKey) {
            const markets = await Globals.shared.getMarkets()
            const candidate = markets.find(m => { return m.id === marketKey })
            if (candidate) {
                market = candidate
                isNewMarket = false
            }
        }

        const snapshot = await get(child(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}`), "inventory/product_groups"))
        const productGroups: ProductGroup[] = []
        snapshot.forEach((child) => {
            const group = new ProductGroup(child.val())
            productGroups.push(group)
        })
        const [currencies, taxes] = await Promise.all(promises)

        const productGroupTaxesMap = market.product_group_taxes

        const fiscalRulesModule = child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/configuration/modules/fiscal_rules`)
        const fiscalSnapshot = await get(fiscalRulesModule)
        
        let countries = fiscalSnapshot.val().countries ?? {}
        let fiscalRulesModuleEnabled = fiscalSnapshot.val().enabled ?? false
        let fiscalRule = countries[market.id] ?? "none"
        
        this.setState({ currencies: currencies, productGroups: productGroups, productGroupTaxesMap: productGroupTaxesMap, taxes: taxes, market: market, loading: false, new: isNewMarket, identifier: market.id, countries: countries, fiscalRulesModuleEnabled: fiscalRulesModuleEnabled, fiscalRule: fiscalRule  })
    }

    publish = async () => {
        const market = this.state.market
        const taxes: Dictionary<boolean> = {}
        market.taxes.forEach(tax => {
            taxes[tax] = true
        })

        const productGroupTaxesJSON = {}

        this.state.productGroupTaxesMap.forEach((taxesArray, productGroup) => {
            const taxes: Dictionary<boolean> = {}
            for (const tax of taxesArray) {
                taxes[tax] = true
            }
            productGroupTaxesJSON[productGroup] = taxes
        })
        const marketJson = {
            name: market.name,
            currency: market.currency,
            taxes: taxes,
            product_group_taxes: productGroupTaxesJSON
        }

        this.setState({ publishing: true })

        try {
            await publish(marketJson, "id", this.state.identifier, this.state.new, this.marketsRef(), true)
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        const countriesRef = child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/configuration/modules/fiscal_rules/countries`)
        await set(countriesRef, this.state.countries)
        
        const fiscalEnabledRef = child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/configuration/modules/fiscal_rules/enabled`)
        await set(fiscalEnabledRef, this.state.fiscalRulesModuleEnabled)
        
        this.props.router.navigate("/markets")
    }
}

export default withRoleRouter(MarketEdit)
